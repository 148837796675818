import React, { useState, useEffect } from 'react';
import { budgetTracker } from '../utils/BudgetTrackerInstance';
import { PlusCircle, Trash2 } from 'lucide-react';

interface ExpenseSectionProps {
  darkMode: boolean;
}

const ExpenseSection: React.FC<ExpenseSectionProps> = ({ darkMode }) => {
  const [expenseName, setExpenseName] = useState('');
  const [expenseAmount, setExpenseAmount] = useState<number | ''>('');
  const [expenseCategory, setExpenseCategory] = useState('other');
  const [expenses, setExpenses] = useState<any[]>([]);

  useEffect(() => {
    const handleUpdate = () => {
      const data = budgetTracker.getSavedData();
      setExpenses(data.expenses);
    };

    // Initial load
    handleUpdate();

    // Subscribe to updates
    budgetTracker.on('update', handleUpdate);

    return () => {
      budgetTracker.off('update', handleUpdate);
    };
  }, []);

  const handleAddExpense = () => {
    if (!expenseName || !expenseAmount) {
      alert('Please fill out all fields.');
      return;
    }

    const expense = {
      name: expenseName,
      category: expenseCategory,
      amount: Number(expenseAmount),
    };

    budgetTracker.addExpense(expense);
    
    setExpenseName('');
    setExpenseAmount('');
    setExpenseCategory('other');
  };

  const handleDeleteExpense = (index: number) => {
    budgetTracker.removeExpense(index);
  };

  return (
    <div className={`card shadow-lg rounded-lg overflow-hidden ${darkMode ? 'bg-dark text-white' : 'bg-light text-dark'}`}>
      <div className="card-header p-4 border-bottom">
        <h2 className="text-xl font-semibold">Add Expense</h2>
      </div>
      <div className="card-body p-4">
        <div className="mb-4">
          <label className="form-label">Expense Name</label>
          <input
            type="text"
            value={expenseName}
            onChange={(e) => setExpenseName(e.target.value)}
            className={`form-control ${darkMode ? 'bg-dark text-white' : 'bg-light'}`}
            placeholder="Enter expense name"
          />
        </div>
        
        <div className="mb-4">
          <label className="form-label">Amount (R)</label>
          <input
            type="number"
            value={expenseAmount}
            onChange={(e) => setExpenseAmount(Number(e.target.value))}
            className={`form-control ${darkMode ? 'bg-dark text-white' : 'bg-light'}`}
            placeholder="0.00"
          />
        </div>
        
        <div className="mb-4">
          <label className="form-label">Category</label>
          <select
            value={expenseCategory}
            onChange={(e) => setExpenseCategory(e.target.value)}
            className={`form-select ${darkMode ? 'bg-dark text-white' : 'bg-light'}`}
          >
            <option value="other">Other</option>
            <option value="housing">Housing</option>
            <option value="transportation">Transportation</option>
            <option value="food">Food</option>
            <option value="utilities">Utilities</option>
            <option value="savings">Savings</option>
            <option value="healthcare">Healthcare</option>
            <option value="entertainment">Entertainment</option>
          </select>
        </div>
        
        <button
          onClick={handleAddExpense}
          className="btn btn-primary w-100 d-flex align-items-center justify-content-center gap-2"
        >
          <PlusCircle size={20} />
          Add Expense
        </button>

        <div className="mt-6">
          <h3 className="text-lg font-semibold mb-3">Your Expenses</h3>
          <div className="list-group">
            {expenses.map((expense, index) => (
              <div
                key={index}
                className={`list-group-item d-flex justify-content-between align-items-center rounded-lg ${darkMode ? 'bg-dark text-white' : 'bg-light text-dark'}`}
              >
                <div>
                  <span className="fw-semibold">{expense.name}</span>
                  <span className="text-muted ms-2">({expense.category})</span>
                  <div className="fw-bold">R {expense.amount.toFixed(2)}</div>
                </div>
                <button
                  onClick={() => handleDeleteExpense(index)}
                  className="btn btn-link text-danger"
                >
                  <Trash2 size={20} />
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpenseSection;
