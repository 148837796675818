import { TaxCalculator } from "./TaxCalculator";
import EventEmitter from 'eventemitter3';

interface Grocery {
    groc: string[];
}

interface Expense {
    category: string;
    amount: number;
    subExpenses?: SubExpense[];
}

interface SubExpense {
    description: string;
    amount: number;
}

interface Income {
    description: string;
    amount: number;
}

export class BudgetTracker extends EventEmitter {
    expenses: Expense[] = [];
    additionalIncomes: Income[] = [];
    salary = 0;
    netSalary = 0;
    useTax = true;
    groceries: Grocery[] = [];
    age: string | null = "under65";

    constructor() {
        super();
        this.loadData();
    }

    getGroceries(): Grocery[] {
        return this.groceries;
    }

    removeGrocery(index: number): void {
        this.groceries.splice(index, 1);
        this.saveData();
    }

    addGroceries(grocery: Grocery): void {
        grocery.groc = [];
        this.groceries.push(grocery);
        this.saveData();
    }
    
    updateSalaryDetails(grossSalary: number): string {
        this.updateSalary(grossSalary);
        const ageCategory = this.age|| 'under65';
        let age: number;
        switch(ageCategory) {
            case 'under65': age = 30; break;
            case '65to74': age = 70; break;
            case '75andOver': age = 80; break;
            default: age = 30;
        }
        const { tax, UIF, netSalary } = TaxCalculator.calculateNetSalary(grossSalary, age);
        const annualIncome = grossSalary * 12;
        this.updateNetSalary(netSalary);
        let taxMessage = '';
        if (tax === 0) {
            if (age < 65 && annualIncome <= TaxCalculator.TAX_THRESHOLDS.under65) {
                taxMessage = `You don't pay income tax because your annual income (R${annualIncome.toFixed(2)}) is below the tax threshold of R${TaxCalculator.TAX_THRESHOLDS.under65} for your age group.`;
            } else if (age >= 65 && age < 75 && annualIncome <= TaxCalculator.TAX_THRESHOLDS.under75) {
                taxMessage = `You don't pay income tax because your annual income (R${annualIncome.toFixed(2)}) is below the tax threshold of R${TaxCalculator.TAX_THRESHOLDS.under75} for your age group.`;
            } else if (age >= 75 && annualIncome <= TaxCalculator.TAX_THRESHOLDS.over75) {
                taxMessage = `You don't pay income tax because your annual income (R${annualIncome.toFixed(2)}) is below the tax threshold of R${TaxCalculator.TAX_THRESHOLDS.over75} for your age group.`;
            }
        } else {
            taxMessage = `You pay income tax because your annual income (R${annualIncome.toFixed(2)}) is above the tax threshold for your age group.`;
        }
        return `
                <div class="row">
                    <div class="col-sm-6">
                        <p class="mb-1"><strong>Monthly Tax:</strong> R${parseFloat(tax.toString()).toFixed(2)}</p>
                    </div>
                    <div class="col-sm-6">
                        <p class="mb-1"><strong>Net Salary:</strong> R${parseFloat(netSalary.toString()).toFixed(2)} 
                        <span class="text-muted">(UIF: R${parseFloat(UIF.toString()).toFixed(2)})</span></p>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-12">
                        <p class="mb-1 text-info">${taxMessage}</p>
                    </div>
                </div>
            `;

         
    }

    setUseTax(value: boolean): void {
        this.useTax = value;
        this.saveData();
    }

    addExpense(expense: Expense): void {
        expense.subExpenses = [];
        this.expenses.push(expense);
        this.saveData();
    }

    addSubExpense(parentIndex: number, subExpense: { amount: number, description: string }): void {
        const parentExpense = this.expenses[parentIndex];
        if (parentExpense) {
            if (!parentExpense.subExpenses) {
                parentExpense.subExpenses = [];
            }
            parentExpense.subExpenses.push(subExpense);
            this.saveData();
        }
    }
    

    removeExpense(index: number): void {
        this.expenses.splice(index, 1);
        this.saveData(); // Ensure the data is saved after deletion
        this.emit('update'); // Emit the update event after modifying the expenses
    }

    removeSubExpense(parentIndex: number, subIndex: number): void {
        const parentExpense = this.expenses[parentIndex];
        if (parentExpense && parentExpense.subExpenses) {
            parentExpense.subExpenses.splice(subIndex, 1);
            this.saveData();
        }
    }

    addIncome(income: Income): void {
        this.additionalIncomes.push(income);
        this.saveData();
    }

    removeIncome(index: number): void {
        this.additionalIncomes.splice(index, 1);
        this.saveData();
    }

    updateSalary(amount: number): void {
        this.salary = amount;
        this.saveData();
    }

    updateNetSalary(amount: number): void {
        this.netSalary = amount;
        this.saveData();
    }

    updateAge(age: string): void {
        this.age = age;
        this.saveData();
    }

    getTotalIncome(): number {
        const baseSalary = this.netSalary || 0; 
        const additionalIncome = this.additionalIncomes?.reduce(
          (sum, income) => sum + (income.amount || 0),
          0
        );
        return baseSalary + (additionalIncome || 0);
      }
      

    getTotalExpenses(): number {
        return this.expenses.reduce((sum, expense) => {
            const subExpenseTotal = expense.subExpenses?.reduce((subSum, subExp) => subSum + (subExp.amount || 0), 0) || 0;
            return sum + (expense.amount || 0) + subExpenseTotal;
        }, 0);
    }

    getBalance(): number {
        return this.getTotalIncome() - this.getTotalExpenses();
    }

    analyzeBudget(): string {
        let analysis = '';  // Start with an empty string to accumulate HTML content
    
        const totalIncome = this.getTotalIncome();
        const totalExpenses = this.getTotalExpenses();
        const balance = this.getBalance();
        const savingsRate = totalIncome > 0 ? (balance / totalIncome) * 100 : 0;
    
        if (totalExpenses > totalIncome) {
            analysis += "<p><strong>⚠️</strong> Your expenses exceed your income. This is unsustainable long-term.</p>";
            analysis += "<p><strong>💡</strong> Consider reducing non-essential expenses or finding additional income sources.</p>";
        }
    
        if (savingsRate < 0) {
            analysis += "<p><strong>🚨</strong> You're in a deficit spending situation.</p>";
        } else if (savingsRate < 20) {
            analysis += "<p><strong>⚠️</strong> Your savings rate is below recommended levels (20%).</p>";
            analysis += "<p><strong>💡</strong> Try to increase your savings by reducing discretionary spending.</p>";
        } else if (savingsRate >= 20 && savingsRate < 30) {
            analysis += "<p><strong>👍</strong> Good job! You're saving at a healthy rate.</p>";
        } else {
            analysis += "<p><strong>🌟</strong> Excellent savings rate! Consider investing your surplus.</p>";
        }
    
        const categoryTotals: { [key: string]: number } = {};
        this.expenses.forEach(expense => {
            if (!categoryTotals[expense.category]) {
                categoryTotals[expense.category] = 0;
            }
            const subExpenseTotal = expense.subExpenses?.reduce((sum, subExp) => sum + (subExp.amount || 0), 0) || 0;
            categoryTotals[expense.category] += (expense.amount || 0) + subExpenseTotal;
        });
    
        if (totalIncome > 0) {
            Object.entries(categoryTotals).forEach(([category, amount]) => {
                const percentage = (amount / totalIncome) * 100;
    
                switch (category) {
                    case 'housing':
                        if (percentage > 30) {
                            analysis += `<p><strong>⚠️</strong> Housing expenses (${percentage.toFixed(1)}%) exceed recommended 30% of income.</p>`;
                        }
                        break;
                    case 'transportation':
                        if (percentage > 15) {
                            analysis += `<p><strong>⚠️</strong> Transportation expenses (${percentage.toFixed(1)}%) exceed recommended 15% of income.</p>`;
                        }
                        break;
                    case 'food':
                        if (percentage > 10) {
                            analysis += `<p><strong>⚠️</strong> Food expenses (${percentage.toFixed(1)}%) exceed recommended 10% of income.</p>`;
                        }
                        break;
                    case 'savings':
                        if (percentage < 20) {
                            analysis += `<p><strong>⚠️</strong> Savings are low (${percentage.toFixed(1)}%). Aim for at least 20%.</p>`;
                        }
                        break;
                    default:
                        break;
                }
            });
        }
    
        return analysis;  // Return the accumulated HTML string
    }
    

    saveData(): void {
        const data = {
            expenses: this.expenses,
            additionalIncomes: this.additionalIncomes,
            salary: this.salary,
            netSalary: this.netSalary,
            useTax: this.useTax,
            groceries: this.groceries,
            ageCategory: this.age
        };
        localStorage.setItem('budgetData', JSON.stringify(data));

        this.emit('update', this);
    }

    loadData(): void {
        const savedData = localStorage.getItem('budgetData');
        if (savedData) {
            const data = JSON.parse(savedData);
            this.expenses = data.expenses || [];
            this.additionalIncomes = data.additionalIncomes || [];
            this.salary = data.salary || 0;
            this.netSalary = data.netSalary || 0;
            this.useTax = data.useTax || true;
            this.groceries = data.groceries || [];
            this.age = data.ageCategory || ""
        }
    }

    getSavedData(): { expenses: any[]; additionalIncomes: any[]; salary: number; netSalary: number; useTax: boolean; groceries: any[],ageCategory:string | null } {
        return {
            expenses: this.expenses,
            additionalIncomes: this.additionalIncomes,
            salary: this.salary,
            netSalary: this.netSalary,
            useTax: this.useTax,
            groceries: this.groceries,
            ageCategory: this.age
        };
    }
}
