export async function subscribeToPushNotifications(): Promise<boolean> {
    if ('serviceWorker' in navigator && 'PushManager' in window) {
      try {
        const registration = await navigator.serviceWorker.ready;
        const subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(process.env.REACT_APP_VAPID_PUBLIC_KEY || '')
        });
        
        await sendSubscriptionToServer(subscription);
        
        console.log('Push notification subscription successful', subscription);
        return true;
      } catch (error) {
        console.error('Failed to subscribe to push notifications', error);
        return false;
      }
    }
    return false;
  }
  
  function urlBase64ToUint8Array(base64String: string): Uint8Array {
    const padding = '='.repeat((
  4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');
  
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
  
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }
  
  async function sendSubscriptionToServer(subscription: PushSubscription): Promise<void> {
    // Implement this function to send the subscription to your server
    // This is where you would typically make an API call to your backend
    console.log('Sending subscription to server', subscription);
    // TODO: Implement the actual server communication
  }
  
  