import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { isPWAInstalled } from '../utils/pwaHelper';

interface SidebarProps {
  isOpen: boolean;
  onClose: () => void;
  darkMode: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, onClose, darkMode }) => {
  const [showInstallPrompt, setShowInstallPrompt] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
  const [isIOS, setIsIOS] = useState(false);
  const [installClicked, setInstallClicked] = useState(false); // Track if the install button was clicked
  const [showModal, setShowModal] = useState(false); // Track if the modal is showing

  useEffect(() => {
    // Detect if it's an iOS device
    const isIOSDevice = /(Mac|iPhone|iPod|iPad)/i.test(navigator.userAgent);
    setIsIOS(isIOSDevice);

    const handleBeforeInstallPrompt = (e: Event) => {
      e.preventDefault(); // Prevent default behavior (showing the prompt)
      setDeferredPrompt(e); // Store the event for later use
      setShowInstallPrompt(true); // Show the install button (for Android)
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []); // No need to include `showInstallPrompt` because it's updated internally

  const handleInstallClick = () => {
    if (isIOS) {
      setInstallClicked(true); // Show iOS install instructions after clicking the install button
      setShowModal(true); // Open the modal
      onClose(); // Close the sidebar
    } else if (deferredPrompt) {
      deferredPrompt.prompt(); // Show the PWA install prompt
      deferredPrompt.userChoice.then((choiceResult: { outcome: string }) => {
        console.log(`User response: ${choiceResult.outcome}`);
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }

        setDeferredPrompt(null); // Reset deferred prompt after user choice
      });
    }
  };

  const closeInstallBanner = () => {
    setInstallClicked(false); // Close the iOS install instructions
    setShowModal(false); // Close the modal
  };

  const styles = {
    sidebar: {
      position: 'fixed' as const,
      top: 0,
      left: 0,
      height: '100%',
      width: '250px',
      backgroundColor: darkMode ? '#333' : '#fff',
      boxShadow: '2px 0 5px rgba(0, 0, 0, 0.2)',
      transform: isOpen ? 'translateX(0)' : 'translateX(-100%)',
      transition: 'transform 0.3s ease',
      zIndex: 1050,
      color: darkMode ? '#fff' : '#000',
      display: 'flex',
      flexDirection: 'column' as const,
      justifyContent: 'space-between',
    },
    logo: {
      padding: '20px',
      textAlign: 'center' as const,
    },
    ul: {
      listStyle: 'none',
      padding: 0,
      margin: 0,
      flex: 1,
    },
    li: {
      padding: '15px 20px',
      borderBottom: darkMode
        ? '1px solid rgba(255, 255, 255, 0.1)'
        : '1px solid rgba(0, 0, 0, 0.1)',
    },
    link: {
      textDecoration: 'none',
      color: 'inherit',
      display: 'block',
      fontSize: '16px',
    },
    versionText: {
      margin: '20px 20px 0',
      fontSize: '12px',
      color: darkMode ? 'white' : '#555',
      textAlign: 'center' as const,
    },
    overlay: {
      position: 'fixed' as const,
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      background: 'rgba(0, 0, 0, 0.5)',
      opacity: isOpen ? 1 : 0,
      visibility: isOpen ? 'visible' : 'hidden',
      transition: 'opacity 0.3s ease, visibility 0.3s ease',
      zIndex: 1040,
    } as React.CSSProperties,
    modal: {
      position: 'fixed' as const,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      padding: '20px',
      background: '#fff',
      borderRadius: '8px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      zIndex: 1050,
    },
    modalHeader: {
      fontSize: '18px',
      marginBottom: '10px',
    },
    modalBody: {
      fontSize: '14px',
      marginBottom: '20px',
    },
    modalFooter: {
      textAlign: 'right' as const,
    },
  };

  return (
    <>
      <div id="sideNav" style={styles.sidebar}>
        <div style={{ ...styles.logo, backgroundColor: darkMode ? 'white' : 'transparent' }}>
          <img src="logo.png" alt="BudgetZA Logo" style={{ maxHeight: '50px' }} />
        </div>
        <ul style={styles.ul}>
          <li style={styles.li}>
            <Link to="/" onClick={onClose} style={styles.link}>
              Budget
            </Link>
          </li>
          <li style={styles.li}>
            <Link to="/grocery-list" onClick={onClose} style={styles.link}>
              Grocery List
            </Link>
          </li>
          <li style={styles.li}>
            <Link to="/privacy-policy" onClick={onClose} style={styles.link}>
              Privacy Policy
            </Link>
          </li>
          <li style={styles.li}>
            <Link to="/settings" onClick={onClose} style={styles.link}>
              Settings
            </Link>
          </li>
          {!isPWAInstalled() && (
            <li style={styles.li}>
              <a href="#" id="pwaInstallBtn" onClick={handleInstallClick} style={styles.link}>
                Install App
              </a>
            </li>
          )}
        </ul>
        <p style={styles.versionText}>{`Version: 1.0.0`}</p>
      </div>
      <div id="overlay" style={styles.overlay} onClick={onClose}></div>
      {isIOS && installClicked && showModal && (
        <div style={styles.modal}>
          <div style={styles.modalHeader}>
            <strong>Install this app on your iPhone:</strong>
          </div>
          <div style={styles.modalBody}>
            <ol>
              <li>Tap the Share button on the browser's toolbar</li>
              <li>Tap "Add to Home Screen"</li>
              <li>Tap "Add" in the upper right corner</li>
            </ol>
          </div>
          <div style={styles.modalFooter}>
            <button onClick={closeInstallBanner}>Close</button>
          </div>
        </div>
      )}
    </>
  );
};

export default Sidebar;
