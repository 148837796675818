import React, { useEffect, useState, useCallback } from 'react';
import ApexCharts from 'react-apexcharts';
import { budgetTracker } from '../utils/BudgetTrackerInstance';

const ExpenseChart: React.FC<{ darkMode: boolean }> = ({ darkMode }) => {
  const [chartData, setChartData] = useState<any>(null);

  const updateSummary = useCallback(() => {
    const expenses = budgetTracker.getSavedData().expenses;
    const categoryTotals: { [key: string]: number } = {};

    expenses.forEach((expense: { category: string; amount: number; subExpenses: { amount: number }[] }) => {
      const subExpensesTotal = expense.subExpenses?.reduce((sum, subExp) => sum + subExp.amount, 0) || 0;
      const totalAmount = expense.amount + subExpensesTotal;

      if (categoryTotals[expense.category]) {
        categoryTotals[expense.category] += totalAmount;
      } else {
        categoryTotals[expense.category] = totalAmount;
      }
    });

    const labels = Object.keys(categoryTotals);
    const dataValues = Object.values(categoryTotals);

    setChartData({
      options: {
        chart: {
          type: 'donut',
          background: darkMode ? '#1f2937' : '#ffffff',
        },
        labels: labels,
        colors: ['#3b82f6', '#ef4444', '#10b981', '#f59e0b', '#6366f1', '#ec4899', '#8b5cf6'],
        plotOptions: {
          pie: {
            donut: {
              size: '70%',
              labels: {
                show: true,
                total: {
                  show: true,
                  label: 'Total',
                  formatter: function (w: any) {
                    return 'R ' + w.globals.seriesTotals.reduce((a: number, b: number) => a + b, 0).toFixed(2);
                  },
                },
              },
            },
          },
        },
        legend: {
          position: 'bottom',
          labels: {
            colors: darkMode ? '#ffffff' : '#000000',
          },
        },
        theme: {
          mode: darkMode ? 'dark' : 'light',
        },
        tooltip: {
          y: {
            formatter: function (value: number) {
              return 'R ' + value.toFixed(2);
            },
          },
        },
      },
      series: dataValues,
    });
  }, [darkMode]);

  useEffect(() => {
    updateSummary();
    const handleUpdate = () => updateSummary();
    budgetTracker.on('update', handleUpdate);
    return () => {
      budgetTracker.off('update', handleUpdate);
    };
  }, [updateSummary]);

  if (!chartData) {
    return <div>Loading...</div>;
  }

  return (
    <div
      className={`card shadow border-0 ${darkMode ? 'bg-dark text-white' : 'bg-light text-dark'}`}
      style={{ marginTop: '1.5rem', borderRadius: '10px' }}
    >
      <div className="card-body">
        <h3 className="card-title text-center" style={{ fontSize: '1.25rem', marginBottom: '1rem' }}>
          Expense Distribution
        </h3>
        <div className="d-flex justify-content-center">
          <div style={{ width: '100%', maxWidth: '400px' }}>
            <ApexCharts
              options={chartData.options}
              series={chartData.series}
              type="donut"
              height={350}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpenseChart;
