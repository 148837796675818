import React, { useState } from 'react';
import IncomeSection from '../components/IncomeSection';
import ExpenseSection from '../components/ExpenseSection';
import ExpenseChart from '../components/ExpenseChart';
import Summary from '../components/Summary';
import GroceryList from './GroceryList';
import ReactTooltip from 'react-tooltip';

interface BudgetProps {
  darkMode: boolean;
}

const Budget: React.FC<BudgetProps> = ({ darkMode }) => {
  return (
    <div className="container py-4">
      <div className="row">
        <div className="col-md-6 mb-4">
          <IncomeSection darkMode={darkMode} />
        </div>
        <div className="col-md-6 mb-4">
          <ExpenseSection darkMode={darkMode} />
        </div>
      </div>
         <ExpenseChart darkMode={darkMode} />
      <Summary darkMode={darkMode} />
    </div>
  );
};

export default Budget;
