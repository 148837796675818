import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Budget from './pages/Budget';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Settings from './pages/Settings';
import CookieConsent from './components/CookieConsent';
import { sendPushNotification } from './utils/notificationSender';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/App.css';
import GroceryList from './pages/GroceryList';

const App: React.FC = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [darkMode, setDarkMode] = useState<boolean>(false);

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    const isDark = storedTheme === 'dark';
    setDarkMode(isDark); 
    document.documentElement.classList.toggle('dark', isDark); 
  }, []);

  useEffect(() => {
    document.documentElement.classList.toggle('dark', darkMode);
  }, [darkMode]);

  const changeTheme = (val: string) => {
    const isDark = val === 'dark';
    localStorage.setItem('theme', isDark ? 'dark' : 'light');
    setDarkMode(isDark); 
  };

  const toggleSidebar = (): void => {
    setIsSidebarOpen(!isSidebarOpen);
    console.log('Sidebar toggled:', !isSidebarOpen);
  };

  useEffect(() => {
    const scheduleNotification = (): void => {
      sendPushNotification();
      setTimeout(scheduleNotification, 24 * 60 * 60 * 1000); // Schedule the next notification in 24 hours
    };

    const scheduleFirstNotification = () => {
      const now = new Date();
      const msUntil8PM = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 20, 0, 0, 0).getTime() - now.getTime();

      if (msUntil8PM <= 0) {
        // If 8 PM has already passed today, schedule for the next day at 8 PM
        setTimeout(scheduleNotification, msUntil8PM + 24 * 60 * 60 * 1000);
      } else {
        // Schedule for today at 8 PM
        setTimeout(scheduleNotification, msUntil8PM);
      }
    };

    // Schedule the first notification
    scheduleFirstNotification();

  }, []);

  return (
    <Router>
      <div className={`app-container ${darkMode ? 'dark-mode' : ''}`}>
        <Header toggleSidebar={toggleSidebar} darkMode={darkMode} />
        <Sidebar isOpen={isSidebarOpen} onClose={() => setIsSidebarOpen(false)} darkMode={darkMode} />
        <main className="main-content">
          <Routes>
            <Route path="/" element={<Budget darkMode={darkMode} />} />
            <Route path="/grocery-list" element={<GroceryList darkMode={darkMode} />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy darkMode={darkMode} />} />
            <Route path="/settings" element={<Settings darkMode={darkMode} setDarkMode={(e) => changeTheme( e ? 'dark' : 'light')} />} />
          </Routes>
        </main>
        <CookieConsent darkMode={darkMode} />
      </div>
    </Router>
  );
};

export default App;

