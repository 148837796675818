import React, { useState, useEffect } from 'react'
import { Trash2, Edit } from 'lucide-react'
import ApexCharts from 'react-apexcharts'

enum GroceryCategory {
  MEAT = 'meat',
  NON_PERISHABLES = 'non-perishables',
  PRODUCE = 'produce',
  HOUSEHOLD_SUPPLIES = 'household supplies'
}

interface GroceryItem {
  name: string
  price: number
  quantity: number
  done: boolean
  category: GroceryCategory
}

interface GroceryListProps {
  darkMode: boolean
}

class BudgetTracker {
  private storageKey = 'groceryList'

  getGroceries(): GroceryItem[] {
    if (typeof window !== 'undefined') {
      const storedItems = localStorage.getItem(this.storageKey)
      return storedItems ? JSON.parse(storedItems) : []
    }
    return []
  }

  addGroceries(groc: GroceryItem[]): void {
    if (typeof window !== 'undefined') {
      localStorage.setItem(this.storageKey, JSON.stringify(groc))
    }
  }
}

const Button: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({ className, ...props }) => (
  <button className={`btn ${className}`} {...props} />
)

const Input: React.FC<React.InputHTMLAttributes<HTMLInputElement> & { label: string }> = ({ className, label, ...props }) => (
  <div className="form-group">
    <label>{label}</label>
    <input className={`form-control ${className}`} {...props} />
  </div>
)

const Select: React.FC<React.SelectHTMLAttributes<HTMLSelectElement> & { label: string }> = ({ className, label, ...props }) => (
  <div className="form-group">
    <label>{label}</label>
    <select className={`form-select ${className}`} {...props} />
  </div>
)

const Modal: React.FC<{ isOpen: boolean; onClose: () => void; children: React.ReactNode }> = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null

  return (
    <div className="modal d-block" tabIndex={-1} role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Edit Item</h5>
            <button type="button" className="btn-close" onClick={onClose} aria-label="Close"></button>
          </div>
          <div className="modal-body">
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

const GroceryList: React.FC<GroceryListProps> = ({ darkMode }) => {
  const [groceryItems, setGroceryItems] = useState<GroceryItem[]>([])
  const [newItem, setNewItem] = useState<GroceryItem>({
    name: '',
    price: 0,
    quantity: 1,
    done: false,
    category: GroceryCategory.MEAT,
  })
  const [editingItem, setEditingItem] = useState<GroceryItem | null>(null)
  const [modalOpen, setModalOpen] = useState(false)

  const budgetTracker = new BudgetTracker()

  useEffect(() => {
    const savedItems = budgetTracker.getGroceries()
    setGroceryItems(savedItems)
  }, [])

  const addItem = () => {
    if (newItem.name && newItem.price > 0) {
      const updatedItems = [...groceryItems, newItem]
      setGroceryItems(updatedItems)
      budgetTracker.addGroceries(updatedItems)
      setNewItem({ name: '', price: 0, quantity: 1, done: false, category: GroceryCategory.MEAT })
    }
  }

  const toggleDone = (index: number) => {
    const updatedItems = [...groceryItems]
    updatedItems[index].done = !updatedItems[index].done
    setGroceryItems(updatedItems)
    budgetTracker.addGroceries(updatedItems)
  }

  const deleteItem = (name: string) => {
    const updatedItems = groceryItems.filter(item => item.name !== name)
    setGroceryItems(updatedItems)
    budgetTracker.addGroceries(updatedItems)
  }

  const updateItem = () => {
    if (editingItem) {
      const updatedItems = groceryItems.map((item) =>
        item.name === editingItem.name ? editingItem : item
      )
      setGroceryItems(updatedItems)
      budgetTracker.addGroceries(updatedItems)
      setModalOpen(false)
    }
  }

  const resetList = () => {
    setGroceryItems([])
    budgetTracker.addGroceries([])
  }

  const resetDone = () => {
    const updatedItems = groceryItems.map(item => ({ ...item, done: false }))
    setGroceryItems(updatedItems)
    budgetTracker.addGroceries(updatedItems)
  }

  const chartData = () => {
    const categoryTotals = groceryItems.reduce((acc, item) => {
      const total = item.price * item.quantity
      acc[item.category] = (acc[item.category] || 0) + total
      return acc
    }, {} as Record<GroceryCategory, number>)

    return {
      series: Object.values(categoryTotals),
      options: {
        chart: {
          type: 'pie' as const,
          background: 'transparent',
          foreColor: darkMode ? '#fff' : '#000',
        },
        labels: Object.keys(categoryTotals),
        colors: ['#FF5733', '#33FF57', '#5733FF', '#FF33A6'],
        legend: {
          position: 'bottom' as const,
          labels: {
            colors: darkMode ? '#fff' : '#000',
          },
        },
        tooltip: {
          y: {
            formatter: (value: number) => `R${value.toFixed(2)}`,
          },
        },
        title: {
          text: 'Grocery Categories Breakdown',
          style: {
            fontSize: '16px',
            fontWeight: 'bold',
            color: darkMode ? '#fff' : '#000',
          },
        },
      },
    }
  }

  return (
    <div className={`container ${darkMode ? 'bg-dark text-light' : 'bg-light text-dark'}`}>
      <h3 className="text-center my-4">Grocery List</h3>

      <div className="row g-3 mb-3">
        <div className="col-md">
          <Input
            label="Item Name"
            type="text"
            value={newItem.name}
            onChange={(e) => setNewItem({ ...newItem, name: e.target.value })}
            placeholder="Item Name"
          />
        </div>
        <div className="col-md-2">
          <Input
            label="Price"
            type="number"
            value={newItem.price}
            onChange={(e) => setNewItem({ ...newItem, price: parseFloat(e.target.value) })}
            placeholder="Price"
          />
        </div>
        <div className="col-md-2">
          <Input
            label="Quantity"
            type="number"
            value={newItem.quantity}
            onChange={(e) => setNewItem({ ...newItem, quantity: parseInt(e.target.value) })}
            placeholder="Quantity"
          />
        </div>
        <div className="col-md-2">
          <Select
            label="Category"
            value={newItem.category}
            onChange={(e) => setNewItem({ ...newItem, category: e.target.value as GroceryCategory })}
          >
            <option value={GroceryCategory.MEAT}>Meat</option>
            <option value={GroceryCategory.NON_PERISHABLES}>Non-Perishables</option>
            <option value={GroceryCategory.PRODUCE}>Produce</option>
            <option value={GroceryCategory.HOUSEHOLD_SUPPLIES}>Household Supplies</option>
          </Select>
        </div>
        <div className="col-md-2">
          <Button onClick={addItem} className="btn-success w-100 mt-4">
            Add
          </Button>
        </div>
      </div>

      {groceryItems.length > 0 ? (
        <ul className="list-group mb-3">
          {groceryItems.map((item, index) => (
            <li
              key={index}
              className={`list-group-item d-flex justify-content-between align-items-center ${
                darkMode ? 'bg-secondary text-light' : ''
              }`}
            >
              <span className={item.done ? 'text-decoration-line-through' : ''}>{item.name}</span>
              <span>R{(item.price * item.quantity).toFixed(2)}</span>
              <div>
                <Edit
                  size={20}
                  onClick={() => {
                    setEditingItem(item)
                    setModalOpen(true)
                  }}
                  className="cursor-pointer me-2"
                />
                <Trash2
                  size={20}
                  onClick={() => deleteItem(item.name)}
                  className="cursor-pointer me-2"
                />
                <input
                  type="checkbox"
                  checked={item.done}
                  onChange={() => toggleDone(index)}
                  className="form-check-input"
                />
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-center">No items in the list yet!</p>
      )}

      <div className="d-flex justify-content-center gap-2 mb-3">
        <Button onClick={resetList} className="btn-danger">
          Reset List
        </Button>
        <Button onClick={resetDone} className="btn-warning">
          Reset Done
        </Button>
      </div>

      <div className="mt-4">
        <ApexCharts options={chartData().options} series={chartData().series} type="pie" height={350} />
      </div>

      <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
        {editingItem && (
          <div>
            <Input
              label="Item Name"
              type="text"
              value={editingItem.name}
              onChange={(e) => setEditingItem({ ...editingItem, name: e.target.value })}
            />
            <Input
              label="Price"
              type="number"
              value={editingItem.price}
              onChange={(e) => setEditingItem({ ...editingItem, price: parseFloat(e.target.value) })}
              min="0"
            />
            <Input
              label="Quantity"
              type="number"
              value={editingItem.quantity}
              onChange={(e) => setEditingItem({ ...editingItem, quantity: parseInt(e.target.value) })}
              min="1"
            />
            <Select
              label="Category"
              value={editingItem.category}
              onChange={(e) => setEditingItem({ ...editingItem, category: e.target.value as GroceryCategory })}
            >
              <option value={GroceryCategory.MEAT}>Meat</option>
              <option value={GroceryCategory.NON_PERISHABLES}>Non-Perishables</option>
              <option value={GroceryCategory.PRODUCE}>Produce</option>
              <option value={GroceryCategory.HOUSEHOLD_SUPPLIES}>Household Supplies</option>
            </Select>
            <div className="d-flex justify-content-between mt-3">
              <Button onClick={updateItem} className="btn-primary">
                Update
              </Button>
              <Button onClick={() => setModalOpen(false)} className="btn-secondary">
                Cancel
              </Button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  )
}

export default GroceryList

