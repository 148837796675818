import { isPWAInstalled } from './pwaHelper';

const notificationMessages: string[] = [
  "Time to check your budget! 💰",
  "Don't forget to update your expenses! 📊",
  "Quick budget check-in time! 🕒",
  "How's your spending looking today? 🛍️",
  "Budget update reminder! Keep on track! 🎯",
  "Time for a financial health check! 💪",
];

export const sendPushNotification = (): void => {
  if (!isPWAInstalled()) return;

  const notificationPreference = localStorage.getItem('notificationPreference');
  if (notificationPreference !== 'true') return;

  if ('Notification' in window && Notification.permission === 'granted') {
    const randomMessage = notificationMessages[Math.floor(Math.random() * notificationMessages.length)];
    new Notification('BudgetZA', {
      body: randomMessage,
      icon: '/assets/logo.png',
    });
  }
};

