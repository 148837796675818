import React, { useState, useEffect } from 'react';
import { subscribeToPushNotifications } from '../utils/pushNotificationHelper';
import { isPWAInstalled } from '../utils/pwaHelper';

interface SettingsProps {
  darkMode: boolean;
  setDarkMode: (darkMode: boolean) => void;
}

const Settings: React.FC<SettingsProps> = ({ darkMode, setDarkMode }) => {
  const [pushNotifications, setPushNotifications] = useState<boolean>(false);
  const [showNotificationPopup, setShowNotificationPopup] = useState<boolean>(false);

  useEffect(() => {
    const notificationPreference = localStorage.getItem('notificationPreference');
    if (notificationPreference === null) {
      setShowNotificationPopup(true);
    } else {
      setPushNotifications(notificationPreference === 'true');
    }
  }, []);

  const handlePushNotificationToggle = async (): Promise<void> => {
    if (!isPWAInstalled()) {
      alert('Push notifications are only available when the app is installed as a PWA.');
      return;
    }

    if ('Notification' in window) {
      try {
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
          const subscribed = await subscribeToPushNotifications();
          setPushNotifications(subscribed);
          localStorage.setItem('notificationPreference', subscribed.toString());
          if (subscribed) {
            console.log('Push notifications enabled');
          } else {
            console.log('Failed to subscribe to push notifications');
          }
        } else {
          setPushNotifications(false);
          localStorage.setItem('notificationPreference', 'false');
          console.log('Push notifications denied');
        }
      } catch (error) {
        console.error('Error while handling push notification toggle:', error);
        alert('An error occurred while setting up push notifications. Please try again.');
      }
    }
  };

  const handleNotificationPopupChoice = (choice: boolean): void => {
    setPushNotifications(choice);
    localStorage.setItem('notificationPreference', choice.toString());
    setShowNotificationPopup(false);
    if (choice) {
      handlePushNotificationToggle();
    }
  };

  return (
    <div className="container py-4">
      <h2>Settings</h2>
      <div className="card mt-4">
        <div className="card-body">
          <h5 className="card-title">Appearance</h5>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="darkModeToggle"
              checked={darkMode}
              onChange={(e) => setDarkMode(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="darkModeToggle">
              Dark Mode
            </label>
          </div>
        </div>
      </div>
      <div className="card mt-4">
        <div className="card-body">
          <h5 className="card-title">Notifications</h5>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="pushNotificationToggle"
              checked={pushNotifications}
              onChange={handlePushNotificationToggle}
              disabled={!isPWAInstalled()}
            />
            <label className="form-check-label" htmlFor="pushNotificationToggle">
              Enable Push Notifications
            </label>
          </div>
          {!isPWAInstalled() && (
            <small className="text-muted">
              Push notifications are only available when the app is installed as a PWA.
            </small>
          )}
        </div>
      </div>
      {showNotificationPopup && (
        <div className="notification-popup">
          <h3>Would you like to receive push notifications?</h3>
          <p>We'll send you reminders to check and update your budget.</p>
          <button onClick={() => handleNotificationPopupChoice(true)}>Yes, enable notifications</button>
          <button onClick={() => handleNotificationPopupChoice(false)}>No, thanks</button>
        </div>
      )}
    </div>
  );
};

export default Settings;

