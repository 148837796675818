import React, { useState, useEffect } from 'react';


interface CookieConsentProps {
  darkMode: boolean;
}

const CookieConsent: React.FC<CookieConsentProps> = ({ darkMode }) => {
  const [showConsent, setShowConsent] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setShowConsent(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    setShowConsent(false);
  };

  const handleReject = () => {
    localStorage.setItem('cookieConsent', 'rejected');
    setShowConsent(false);
  };

  if (!showConsent) return null;

  return (
    <div id="cookieConsentModal" className="modal fade show" style={{ display: 'block' }}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="cookieConsentTitle">Cookie Consent</h5>
          </div>
          <div className="modal-body">
            <p>We use cookies to enhance your experience and save your preferences. No personal data is collected or shared.</p>
            <p>For more information, please read our <a href="/privacy-policy">Privacy Policy</a>.</p>
          </div>
          <div className="modal-footer" id="cookieConsentFooter">
            <button type="button" className="btn btn-danger" id="rejectCookiesBtn" onClick={handleReject}>Reject</button>
            <button type="button" className="btn btn-primary" id="acceptCookiesBtn" onClick={handleAccept}>Accept</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;

