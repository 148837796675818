import React, { useState, useEffect } from 'react';
import { budgetTracker } from '../utils/BudgetTrackerInstance';
import { PlusCircle, Trash2, Info } from 'lucide-react';
import ReactTooltip from 'react-tooltip';

interface Income {
  amount: number;
  description: string;
}

interface IncomeSectionProps {
  darkMode: boolean;
}

const IncomeSection: React.FC<IncomeSectionProps> = ({ darkMode }) => {
  const [useTax, setUseTax] = useState(true);
  const [grossSalary, setGrossSalary] = useState<number>(0);
  const [netSalary, setNetSalary] = useState<number>(0);
  const [additionalIncomeModalOpen, setAdditionalIncomeModalOpen] = useState(false);
  const [additionalIncome, setAdditionalIncome] = useState({ amount: 0, description: '' });
  const [additionalIncomes, setAdditionalIncomes] = useState<Income[]>([]);
  const [ageCategory, setAgeCategory] = useState<string>('under65');

  // Load data from the cache on initial load
  useEffect(() => {
    const data = budgetTracker.getSavedData();
    setGrossSalary(data.salary);
    setNetSalary(data.netSalary);
    setAgeCategory(data.ageCategory || 'under65');
    setUseTax(data.useTax);

    // Only load additional incomes into state once from the cache
    if (data.additionalIncomes) {
      setAdditionalIncomes(data.additionalIncomes);
    }

    if (data.useTax && data.salary > 0) {
      const res = budgetTracker.updateSalaryDetails(data.salary);
      const salaryDetails = document.getElementById('salaryDetails');
      if (salaryDetails) {
        salaryDetails.innerHTML = res;
      }
    }
  }, []); // Empty dependency array ensures this runs only once on component mount

  const handleSalaryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value === '' ? 0 : parseFloat(e.target.value);

    if (useTax) {
      setGrossSalary(value);
      const res = budgetTracker.updateSalaryDetails(value);
      const salaryDetails = document.getElementById('salaryDetails');
      if (salaryDetails) {
        salaryDetails.innerHTML = res;
      }
    } else {
      setNetSalary(value);
      budgetTracker.updateNetSalary(value);
    }
  };

  const handleTaxToggle = (checked: boolean) => {
    setUseTax(checked);
    budgetTracker.setUseTax(checked);
  };

  const handleAdditionalIncomeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAdditionalIncome({ ...additionalIncome, [name]: value });
  };

  const handleSaveIncome = () => {
    if (additionalIncome.amount && additionalIncome.description) {
      budgetTracker.addIncome(additionalIncome); // Save to cache
      setAdditionalIncomes((prev) => [...prev, additionalIncome]); // Update state
      setAdditionalIncome({ amount: 0, description: '' });
      setAdditionalIncomeModalOpen(false);
    }
  };

  const handleRemoveIncome = (index: number) => {
    budgetTracker.removeIncome(index); // Update cache
    setAdditionalIncomes((prev) => prev.filter((_, i) => i !== index)); // Update state
  };

  return (
    <div
      className={`card shadow-lg rounded-lg ${
        darkMode ? 'bg-dark text-white' : 'bg-light text-dark'
      }`}
    >
      <div className="card-header">
        <h2 className="text-xl">Income Details</h2>
      </div>

      <div className="card-body">
        <div className="form-check form-switch mb-3">
          <input
            type="checkbox"
            className="form-check-input"
            id="taxToggle"
            checked={useTax}
            onChange={(e) => handleTaxToggle(e.target.checked)}
          />
          <label className="form-check-label" htmlFor="taxToggle">
            Calculate Tax
          </label>
        </div>

        <div className="mb-3">
          <label>
            {useTax
              ? 'Gross Monthly Salary (Before Tax)'
              : 'Net Monthly Salary (After Tax)'}
          </label>
          {useTax && (
            <Info
              size={16}
              style={{
                color: 'blue',
                marginLeft: '0.5rem',
                cursor: 'help',
              }}
              data-tip="Ensure this value is correct."
            />
          )}
          <input
            type="number"
            value={useTax ? grossSalary || '' : netSalary || ''}
            onChange={handleSalaryChange}
            className="form-control mt-2"
            placeholder="Enter amount"
          />
        </div>

        {useTax && (
          <div className="mb-3">
            <label>Age Category</label>
            <select
              value={ageCategory}
              onChange={(e) => setAgeCategory(e.target.value)}
              className="form-select"
            >
              <option value="under65">Under 65</option>
              <option value="65to74">65 - 74</option>
              <option value="75andOver">75 and Over</option>
            </select>
          </div>
        )}

        <div id="salaryDetails" style={{ fontSize: '0.875rem' }}></div>

        <button
          onClick={() => setAdditionalIncomeModalOpen(true)}
          className="btn btn-primary w-100 mt-3"
        >
          <PlusCircle size={20} className="me-2" />
          Add Additional Income
        </button>

        {additionalIncomes.length  > 0 && (
          <div className="mt-4">
            <h3 className="mb-3">Additional Income</h3>
            <ul className="list-group">
              {additionalIncomes.map((income, index) => (
                <li
                  key={index}
                  className={`list-group-item d-flex justify-content-between ${
                    darkMode ? 'list-group-item-dark' : ''
                  }`}
                >
                  <div>
                    <span className="fw-bold">{income.description}</span>
                    <div>R {Number(income.amount).toFixed(2)}</div>
                  </div>
                  <button
                    onClick={() => handleRemoveIncome(index)}
                    className="btn btn-link text-danger"
                  >
                    <Trash2 />
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      <ReactTooltip />

      {additionalIncomeModalOpen && (
        <div
          className="modal fade show d-block"
          tabIndex={-1}
          style={{ zIndex: 1050 }}
          role="dialog"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add Additional Income</h5>
              </div>
              <div className="modal-body">
                <div className="mb-3">
                  <label htmlFor="incomeDescription" className="form-label">
                    Description
                  </label>
                  <input
                    type="text"
                    id="incomeDescription"
                    className="form-control"
                    name="description"
                    value={additionalIncome.description}
                    onChange={handleAdditionalIncomeChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="incomeAmount" className="form-label">
                    Amount (R)
                  </label>
                  <input
                    type="number"
                    id="incomeAmount"
                    className="form-control"
                    name="amount"
                    value={additionalIncome.amount}
                    onChange={handleAdditionalIncomeChange}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setAdditionalIncomeModalOpen(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSaveIncome}
                >
                  Save Income
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default IncomeSection;
