import React, { useState } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';

interface HeaderProps {
  toggleSidebar: () => void;
  darkMode: boolean;
}

const Header: React.FC<HeaderProps> = ({ toggleSidebar, darkMode }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSidebarToggle = () => {
    setIsOpen((prevState) => !prevState);  // Toggle the state
    toggleSidebar();  // Call the passed down toggleSidebar function
  };

  return (
    <header>
      <nav className={`navbar ${darkMode ? 'navbar-dark bg-dark' : 'navbar-light bg-light'}`} style={{ padding: '0 5%' }}>
        <div className="navbar-brand" style={{ backgroundColor: darkMode ? 'white' : 'transparent' }}>
          <img src="logo.png" alt="BudgetZA Logo" style={{ maxHeight: '50px' }} />
        </div>
        <div
          className="navbar-toggle"
          id="burgerMenu"
          onClick={handleSidebarToggle}
          style={{ cursor: 'pointer' }}
        >
          <i
            className="fas fa-bars"
            style={{
              transition: 'transform 0.3s ease-in-out',  // Smooth transition for rotation
              transform: isOpen ? 'rotate(90deg)' : 'rotate(0deg)',  // Rotate by 90 degrees when open, reset to 0deg when closed
            }}
          />
        </div>
      </nav>
    </header>
  );
};

export default Header;
