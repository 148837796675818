import React, { useEffect, useState } from 'react';
import { budgetTracker } from '../utils/BudgetTrackerInstance';
import { DollarSign, TrendingUp, TrendingDown, AlertCircle } from 'lucide-react';

interface SummaryProps {
  darkMode: boolean;
}

const Summary: React.FC<SummaryProps> = ({ darkMode }) => {
  const [totalIncome, setTotalIncome] = useState<string>('0.00');
  const [totalExpenses, setTotalExpenses] = useState<string>('0.00');
  const [balance, setBalance] = useState<string>('0.00');
  const [analysis, setAnalysis] = useState<string>('');

  const updateSummary = () => {
    const income = Number(budgetTracker.getTotalIncome()) || 0;
    const expenses = Number(budgetTracker.getTotalExpenses()) || 0;
    const balanceLeft = Number(budgetTracker.getBalance()) || 0;

    setTotalIncome(income.toFixed(2));
    setTotalExpenses(expenses.toFixed(2));
    setBalance(balanceLeft.toFixed(2));
    setAnalysis(budgetTracker.analyzeBudget());
  };

  useEffect(() => {
    updateSummary();
    const handleUpdate = () => updateSummary();
    budgetTracker.on('update', handleUpdate);
  }, []);

  return (
    <div style={{ marginTop: '1.5rem' }}>
      <div className="row g-4">
        <div className="col-md-4">
          <div
            className={`card shadow-lg rounded ${
              darkMode ? 'bg-dark text-white' : 'bg-light text-dark'
            }`}
          >
            <div className="card-body d-flex align-items-center justify-content-between">
              <div>
                <p className={` mb-1 ${darkMode ? 'bg-dark text-white' : 'bg-light text-dark'}`}>Total Income</p>
                <h4 className="mb-0">R {totalIncome}</h4>
              </div>
              <div
                className="rounded-circle p-3"
                style={{
                  backgroundColor: darkMode ? '#14532d' : '#d1fae5',
                  color: darkMode ? '#34d399' : '#10b981',
                }}
              >
                <DollarSign />
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div
            className={`card shadow-lg rounded ${
              darkMode ? 'bg-dark text-white' : 'bg-light text-dark'
            }`}
          >
            <div className="card-body d-flex align-items-center justify-content-between">
              <div>
                <p className={` mb-1 ${darkMode ? 'bg-dark text-white' : 'bg-light text-dark'}`}>Total Expenses</p>
                <h4 className="mb-0">R {totalExpenses}</h4>
              </div>
              <div
                className="rounded-circle p-3"
                style={{
                  backgroundColor: darkMode ? '#7f1d1d' : '#fee2e2',
                  color: darkMode ? '#f87171' : '#dc2626',
                }}
              >
                <TrendingDown />
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div
            className={`card shadow-lg rounded ${
              darkMode ? 'bg-dark text-white' : 'bg-light text-dark'
            }`}
          >
            <div className="card-body d-flex align-items-center justify-content-between">
              <div>
                <p className={` mb-1 ${darkMode ? 'bg-dark text-white' : 'bg-light text-dark'}`}>Balance</p>
                <h4 className="mb-0">R {balance}</h4>
              </div>
              <div
                className="rounded-circle p-3"
                style={{
                  backgroundColor:
                    Number(balance) >= 0
                      ? darkMode
                        ? '#1e3a8a'
                        : '#dbeafe'
                      : darkMode
                      ? '#7f1d1d'
                      : '#fee2e2',
                  color:
                    Number(balance) >= 0
                      ? darkMode
                        ? '#60a5fa'
                        : '#2563eb'
                      : darkMode
                      ? '#f87171'
                      : '#dc2626',
                }}
              >
                {Number(balance) >= 0 ? <TrendingUp /> : <AlertCircle />}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`card shadow-lg rounded mt-4 ${
          darkMode ? 'bg-dark text-white' : 'bg-light text-dark'
        }`}
      >
        <div className="card-body">
          <h3 className="card-title mb-3">Budget Analysis</h3>
          <div dangerouslySetInnerHTML={{ __html: analysis }} />
        </div>
      </div>
    </div>
  );
};

export default Summary;
